'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Thumbnails = function () {
    function Thumbnails(options) {
        _classCallCheck(this, Thumbnails);
        this.container = options.container;
        this.barWidth = options.barWidth;
        this.container.style.backgroundImage = 'url(\'' + options.url + '\')';
        this.events = options.events;
    }
    _createClass(Thumbnails, [
        {
            key: 'resize',
            value: function resize(width, height) {
                this.container.style.width = width + 'px';
                this.container.style.height = height + 'px';
                this.container.style.top = -height + 2 + 'px';
            }
        },
        {
            key: 'show',
            value: function show() {
                this.container.style.display = 'block';
                this.events && this.events.trigger('thumbnails_show');
            }
        },
        {
            key: 'move',
            value: function move(position) {
                this.container.style.backgroundPosition = '-' + (Math.ceil(position / this.barWidth * 100) - 1) * 160 + 'px 0';
                this.container.style.left = position - this.container.offsetWidth / 2 + 'px';
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.container.style.display = 'none';
                this.events && this.events.trigger('thumbnails_hide');
            }
        }
    ]);
    return Thumbnails;
}();
exports.default = Thumbnails;