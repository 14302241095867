'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _promisePolyfill = require('promise-polyfill');
var _promisePolyfill2 = _interopRequireDefault(_promisePolyfill);
var _utils = require('./utils');
var _utils2 = _interopRequireDefault(_utils);
var _options = require('./options');
var _options2 = _interopRequireDefault(_options);
var _i18n = require('./i18n');
var _i18n2 = _interopRequireDefault(_i18n);
var _template = require('./template');
var _template2 = _interopRequireDefault(_template);
var _icons = require('./icons');
var _icons2 = _interopRequireDefault(_icons);
var _danmaku = require('./danmaku');
var _danmaku2 = _interopRequireDefault(_danmaku);
var _events = require('./events');
var _events2 = _interopRequireDefault(_events);
var _fullscreen = require('./fullscreen');
var _fullscreen2 = _interopRequireDefault(_fullscreen);
var _user = require('./user');
var _user2 = _interopRequireDefault(_user);
var _subtitle = require('./subtitle');
var _subtitle2 = _interopRequireDefault(_subtitle);
var _bar = require('./bar');
var _bar2 = _interopRequireDefault(_bar);
var _timer = require('./timer');
var _timer2 = _interopRequireDefault(_timer);
var _bezel = require('./bezel');
var _bezel2 = _interopRequireDefault(_bezel);
var _controller = require('./controller');
var _controller2 = _interopRequireDefault(_controller);
var _setting = require('./setting');
var _setting2 = _interopRequireDefault(_setting);
var _comment = require('./comment');
var _comment2 = _interopRequireDefault(_comment);
var _hotkey = require('./hotkey');
var _hotkey2 = _interopRequireDefault(_hotkey);
var _contextmenu = require('./contextmenu');
var _contextmenu2 = _interopRequireDefault(_contextmenu);
var _infoPanel = require('./info-panel');
var _infoPanel2 = _interopRequireDefault(_infoPanel);
var _video = require('../template/video.art');
var _video2 = _interopRequireDefault(_video);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var index = 0;
var instances = [];
var DPlayer = function () {
    function DPlayer(options) {
        var _this = this;
        _classCallCheck(this, DPlayer);
        this.options = (0, _options2.default)(options);
        if (this.options.video.quality) {
            this.qualityIndex = this.options.video.defaultQuality;
            this.quality = this.options.video.quality[this.options.video.defaultQuality];
        }
        this.tran = new _i18n2.default(this.options.lang).tran;
        this.events = new _events2.default();
        this.user = new _user2.default(this);
        this.container = this.options.container;
        this.container.classList.add('dplayer');
        if (!this.options.danmaku) {
            this.container.classList.add('dplayer-no-danmaku');
        }
        if (this.options.live) {
            this.container.classList.add('dplayer-live');
        }
        if (_utils2.default.isMobile) {
            this.container.classList.add('dplayer-mobile');
        }
        this.arrow = this.container.offsetWidth <= 500;
        if (this.arrow) {
            this.container.classList.add('dplayer-arrow');
        }
        this.template = new _template2.default({
            container: this.container,
            options: this.options,
            index: index,
            tran: this.tran
        });
        this.video = this.template.video;
        this.bar = new _bar2.default(this.template);
        this.bezel = new _bezel2.default(this.template.bezel);
        this.fullScreen = new _fullscreen2.default(this);
        this.controller = new _controller2.default(this);
        if (this.options.danmaku) {
            this.danmaku = new _danmaku2.default({
                container: this.template.danmaku,
                opacity: this.user.get('opacity'),
                callback: function callback() {
                    setTimeout(function () {
                        _this.template.danmakuLoading.style.display = 'none';
                        if (_this.options.autoplay) {
                            _this.play();
                        }
                    }, 0);
                },
                error: function error(msg) {
                    _this.notice(msg);
                },
                apiBackend: this.options.apiBackend,
                borderColor: this.options.theme,
                height: this.arrow ? 24 : 30,
                time: function time() {
                    return _this.video.currentTime;
                },
                unlimited: this.user.get('unlimited'),
                api: {
                    id: this.options.danmaku.id,
                    address: this.options.danmaku.api,
                    token: this.options.danmaku.token,
                    maximum: this.options.danmaku.maximum,
                    addition: this.options.danmaku.addition,
                    user: this.options.danmaku.user
                },
                events: this.events,
                tran: function tran(msg) {
                    return _this.tran(msg);
                }
            });
            this.comment = new _comment2.default(this);
        }
        this.setting = new _setting2.default(this);
        document.addEventListener('click', function () {
            _this.focus = false;
        }, true);
        this.container.addEventListener('click', function () {
            _this.focus = true;
        }, true);
        this.paused = true;
        this.timer = new _timer2.default(this);
        this.hotkey = new _hotkey2.default(this);
        this.contextmenu = new _contextmenu2.default(this);
        this.initVideo(this.video, this.quality && this.quality.type || this.options.video.type);
        this.infoPanel = new _infoPanel2.default(this);
        if (!this.danmaku && this.options.autoplay) {
            this.play();
        }
        index++;
        instances.push(this);
    }
    _createClass(DPlayer, [
        {
            key: 'seek',
            value: function seek(time) {
                time = Math.max(time, 0);
                if (this.video.duration) {
                    time = Math.min(time, this.video.duration);
                }
                if (this.video.currentTime < time) {
                    this.notice(this.tran('FF') + ' ' + (time - this.video.currentTime).toFixed(0) + ' ' + this.tran('s'));
                } else if (this.video.currentTime > time) {
                    this.notice(this.tran('REW') + ' ' + (this.video.currentTime - time).toFixed(0) + ' ' + this.tran('s'));
                }
                this.video.currentTime = time;
                if (this.danmaku) {
                    this.danmaku.seek();
                }
                this.bar.set('played', time / this.video.duration, 'width');
                this.template.ptime.innerHTML = _utils2.default.secondToTime(time);
            }
        },
        {
            key: 'play',
            value: function play() {
                var _this2 = this;
                this.paused = false;
                if (this.video.paused) {
                    this.bezel.switch(_icons2.default.play);
                }
                this.template.playButton.innerHTML = _icons2.default.pause;
                var playedPromise = _promisePolyfill2.default.resolve(this.video.play());
                playedPromise.catch(function () {
                    _this2.pause();
                }).then(function () {
                });
                this.timer.enable('loading');
                this.container.classList.remove('dplayer-paused');
                this.container.classList.add('dplayer-playing');
                if (this.danmaku) {
                    this.danmaku.play();
                }
                if (this.options.mutex) {
                    for (var i = 0; i < instances.length; i++) {
                        if (this !== instances[i]) {
                            instances[i].pause();
                        }
                    }
                }
            }
        },
        {
            key: 'pause',
            value: function pause() {
                this.paused = true;
                this.container.classList.remove('dplayer-loading');
                if (!this.video.paused) {
                    this.bezel.switch(_icons2.default.pause);
                }
                this.template.playButton.innerHTML = _icons2.default.play;
                this.video.pause();
                this.timer.disable('loading');
                this.container.classList.remove('dplayer-playing');
                this.container.classList.add('dplayer-paused');
                if (this.danmaku) {
                    this.danmaku.pause();
                }
            }
        },
        {
            key: 'switchVolumeIcon',
            value: function switchVolumeIcon() {
                if (this.volume() >= 0.95) {
                    this.template.volumeIcon.innerHTML = _icons2.default.volumeUp;
                } else if (this.volume() > 0) {
                    this.template.volumeIcon.innerHTML = _icons2.default.volumeDown;
                } else {
                    this.template.volumeIcon.innerHTML = _icons2.default.volumeOff;
                }
            }
        },
        {
            key: 'volume',
            value: function volume(percentage, nostorage, nonotice) {
                percentage = parseFloat(percentage);
                if (!isNaN(percentage)) {
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    this.bar.set('volume', percentage, 'width');
                    var formatPercentage = (percentage * 100).toFixed(0) + '%';
                    this.template.volumeBarWrapWrap.dataset.balloon = formatPercentage;
                    if (!nostorage) {
                        this.user.set('volume', percentage);
                    }
                    if (!nonotice) {
                        this.notice(this.tran('Volume') + ' ' + (percentage * 100).toFixed(0) + '%');
                    }
                    this.video.volume = percentage;
                    if (this.video.muted) {
                        this.video.muted = false;
                    }
                    this.switchVolumeIcon();
                }
                return this.video.volume;
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.video.paused) {
                    this.play();
                } else {
                    this.pause();
                }
            }
        },
        {
            key: 'on',
            value: function on(name, callback) {
                this.events.on(name, callback);
            }
        },
        {
            key: 'switchVideo',
            value: function switchVideo(video, danmakuAPI) {
                this.pause();
                this.video.poster = video.pic ? video.pic : '';
                this.video.src = video.url;
                this.initMSE(this.video, video.type || 'auto');
                if (danmakuAPI) {
                    this.template.danmakuLoading.style.display = 'block';
                    this.bar.set('played', 0, 'width');
                    this.bar.set('loaded', 0, 'width');
                    this.template.ptime.innerHTML = '00:00';
                    this.template.danmaku.innerHTML = '';
                    if (this.danmaku) {
                        this.danmaku.reload({
                            id: danmakuAPI.id,
                            address: danmakuAPI.api,
                            token: danmakuAPI.token,
                            maximum: danmakuAPI.maximum,
                            addition: danmakuAPI.addition,
                            user: danmakuAPI.user
                        });
                    }
                }
            }
        },
        {
            key: 'initMSE',
            value: function initMSE(video, type) {
                var _this3 = this;
                this.type = type;
                if (this.options.video.customType && this.options.video.customType[type]) {
                    if (Object.prototype.toString.call(this.options.video.customType[type]) === '[object Function]') {
                        this.options.video.customType[type](this.video, this);
                    } else {
                        console.error('Illegal customType: ' + type);
                    }
                } else {
                    if (this.type === 'auto') {
                        if (/m3u8(#|\?|$)/i.exec(video.src)) {
                            this.type = 'hls';
                        } else if (/.flv(#|\?|$)/i.exec(video.src)) {
                            this.type = 'flv';
                        } else if (/.mpd(#|\?|$)/i.exec(video.src)) {
                            this.type = 'dash';
                        } else {
                            this.type = 'normal';
                        }
                    }
                    if (this.type === 'hls' && (video.canPlayType('application/x-mpegURL') || video.canPlayType('application/vnd.apple.mpegURL'))) {
                        this.type = 'normal';
                    }
                    switch (this.type) {
                    case 'hls':
                        if (Hls) {
                            if (Hls.isSupported()) {
                                var hls = new Hls();
                                hls.loadSource(video.src);
                                hls.attachMedia(video);
                            } else {
                                this.notice('Error: Hls is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Hls.');
                        }
                        break;
                    case 'flv':
                        if (flvjs && flvjs.isSupported()) {
                            if (flvjs.isSupported()) {
                                var flvPlayer = flvjs.createPlayer({
                                    type: 'flv',
                                    url: video.src
                                });
                                flvPlayer.attachMediaElement(video);
                                flvPlayer.load();
                            } else {
                                this.notice('Error: flvjs is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find flvjs.');
                        }
                        break;
                    case 'dash':
                        if (dashjs) {
                            dashjs.MediaPlayer().create().initialize(video, video.src, false);
                        } else {
                            this.notice('Error: Can\'t find dashjs.');
                        }
                        break;
                    case 'webtorrent':
                        if (WebTorrent) {
                            if (WebTorrent.WEBRTC_SUPPORT) {
                                this.container.classList.add('dplayer-loading');
                                var client = new WebTorrent();
                                var torrentId = video.src;
                                client.add(torrentId, function (torrent) {
                                    var file = torrent.files.find(function (file) {
                                        return file.name.endsWith('.mp4');
                                    });
                                    file.renderTo(_this3.video, { autoplay: _this3.options.autoplay }, function () {
                                        _this3.container.classList.remove('dplayer-loading');
                                    });
                                });
                            } else {
                                this.notice('Error: Webtorrent is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Webtorrent.');
                        }
                        break;
                    }
                }
            }
        },
        {
            key: 'initVideo',
            value: function initVideo(video, type) {
                var _this4 = this;
                this.initMSE(video, type);
                this.on('durationchange', function () {
                    if (video.duration !== 1) {
                        _this4.template.dtime.innerHTML = _utils2.default.secondToTime(video.duration);
                    }
                });
                this.on('progress', function () {
                    var percentage = video.buffered.length ? video.buffered.end(video.buffered.length - 1) / video.duration : 0;
                    _this4.bar.set('loaded', percentage, 'width');
                });
                this.on('error', function () {
                    _this4.tran && _this4.notice && _this4.type !== 'webtorrent' & _this4.notice(_this4.tran('Video load failed'), -1);
                });
                this.on('ended', function () {
                    _this4.bar.set('played', 1, 'width');
                    if (!_this4.setting.loop) {
                        _this4.pause();
                    } else {
                        _this4.seek(0);
                        video.play();
                    }
                    if (_this4.danmaku) {
                        _this4.danmaku.danIndex = 0;
                    }
                });
                this.on('play', function () {
                    if (_this4.paused) {
                        _this4.play();
                    }
                });
                this.on('pause', function () {
                    if (!_this4.paused) {
                        _this4.pause();
                    }
                });
                this.on('timeupdate', function () {
                    _this4.bar.set('played', _this4.video.currentTime / _this4.video.duration, 'width');
                    var currentTime = _utils2.default.secondToTime(_this4.video.currentTime);
                    if (_this4.template.ptime.innerHTML !== currentTime) {
                        _this4.template.ptime.innerHTML = currentTime;
                    }
                });
                var _loop = function _loop(i) {
                    video.addEventListener(_this4.events.videoEvents[i], function () {
                        _this4.events.trigger(_this4.events.videoEvents[i]);
                    });
                };
                for (var i = 0; i < this.events.videoEvents.length; i++) {
                    _loop(i);
                }
                this.volume(this.user.get('volume'), true, true);
                if (this.options.subtitle) {
                    this.subtitle = new _subtitle2.default(this.template.subtitle, this.video, this.options.subtitle, this.events);
                    if (!this.user.get('subtitle')) {
                        this.subtitle.hide();
                    }
                }
            }
        },
        {
            key: 'switchQuality',
            value: function switchQuality(index) {
                var _this5 = this;
                if (this.qualityIndex === index || this.switchingQuality) {
                    return;
                } else {
                    this.qualityIndex = index;
                }
                this.switchingQuality = true;
                this.quality = this.options.video.quality[index];
                this.template.qualityButton.innerHTML = this.quality.name;
                var paused = this.video.paused;
                this.video.pause();
                var videoHTML = (0, _video2.default)({
                    current: false,
                    pic: null,
                    screenshot: this.options.screenshot,
                    preload: 'auto',
                    url: this.quality.url,
                    subtitle: this.options.subtitle
                });
                var videoEle = new DOMParser().parseFromString(videoHTML, 'text/html').body.firstChild;
                this.template.videoWrap.insertBefore(videoEle, this.template.videoWrap.getElementsByTagName('div')[0]);
                this.prevVideo = this.video;
                this.video = videoEle;
                this.initVideo(this.video, this.quality.type || this.options.video.type);
                this.seek(this.prevVideo.currentTime);
                this.notice(this.tran('Switching to') + ' ' + this.quality.name + ' ' + this.tran('quality'), -1);
                this.events.trigger('quality_start', this.quality);
                this.on('canplay', function () {
                    if (_this5.prevVideo) {
                        if (_this5.video.currentTime !== _this5.prevVideo.currentTime) {
                            _this5.seek(_this5.prevVideo.currentTime);
                            return;
                        }
                        _this5.template.videoWrap.removeChild(_this5.prevVideo);
                        _this5.video.classList.add('dplayer-video-current');
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.prevVideo = null;
                        _this5.notice(_this5.tran('Switched to') + ' ' + _this5.quality.name + ' ' + _this5.tran('quality'));
                        _this5.switchingQuality = false;
                        _this5.events.trigger('quality_end');
                    }
                });
            }
        },
        {
            key: 'notice',
            value: function notice(text) {
                var _this6 = this;
                var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
                var opacity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.8;
                this.template.notice.innerHTML = text;
                this.template.notice.style.opacity = opacity;
                if (this.noticeTime) {
                    clearTimeout(this.noticeTime);
                }
                this.events.trigger('notice_show', text);
                if (time > 0) {
                    this.noticeTime = setTimeout(function () {
                        _this6.template.notice.style.opacity = 0;
                        _this6.events.trigger('notice_hide');
                    }, time);
                }
            }
        },
        {
            key: 'resize',
            value: function resize() {
                if (this.danmaku) {
                    this.danmaku.resize();
                }
                this.events.trigger('resize');
            }
        },
        {
            key: 'speed',
            value: function speed(rate) {
                this.video.playbackRate = rate;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                instances.splice(instances.indexOf(this), 1);
                this.pause();
                this.controller.destroy();
                this.timer.destroy();
                this.video.src = '';
                this.container.innerHTML = '';
                this.events.trigger('destroy');
            }
        }
    ], [{
            key: 'version',
            get: function get() {
                return DPLAYER_VERSION;
            }
        }]);
    return DPlayer;
}();
exports.default = DPlayer;