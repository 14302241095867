'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
        for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
            arr2[i] = arr[i];
        }
        return arr2;
    } else {
        return Array.from(arr);
    }
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var ContextMenu = function () {
    function ContextMenu(player) {
        var _this = this;
        _classCallCheck(this, ContextMenu);
        this.player = player;
        [].concat(_toConsumableArray(this.player.template.menuItem)).map(function (item, index) {
            if (_this.player.options.contextmenu[index].click) {
                item.addEventListener('click', function () {
                    _this.player.options.contextmenu[index].click(_this.player);
                    _this.hide();
                });
            }
            return item;
        });
        this.player.container.addEventListener('contextmenu', function (e) {
            var event = e || window.event;
            event.preventDefault();
            var clientRect = _this.player.container.getBoundingClientRect();
            _this.show(event.clientX - clientRect.left, event.clientY - clientRect.top);
            _this.player.template.mask.addEventListener('click', function () {
                _this.hide();
            });
        });
    }
    _createClass(ContextMenu, [
        {
            key: 'show',
            value: function show(x, y) {
                this.player.template.menu.classList.add('dplayer-menu-show');
                var clientRect = this.player.container.getBoundingClientRect();
                if (x + this.player.template.menu.offsetWidth >= clientRect.width) {
                    this.player.template.menu.style.right = clientRect.width - x + 'px';
                    this.player.template.menu.style.left = 'initial';
                } else {
                    this.player.template.menu.style.left = x + 'px';
                    this.player.template.menu.style.right = 'initial';
                }
                if (y + this.player.template.menu.offsetHeight >= clientRect.height) {
                    this.player.template.menu.style.bottom = clientRect.height - y + 'px';
                    this.player.template.menu.style.top = 'initial';
                } else {
                    this.player.template.menu.style.top = y + 'px';
                    this.player.template.menu.style.bottom = 'initial';
                }
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.events.trigger('contextmenu_show');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.template.mask.classList.remove('dplayer-mask-show');
                this.player.template.menu.classList.remove('dplayer-menu-show');
                this.player.events.trigger('contextmenu_hide');
            }
        }
    ]);
    return ContextMenu;
}();
exports.default = ContextMenu;